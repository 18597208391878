.loader-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../../assets/night-sky.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;

  p {
    font-size: 20px;
    font-weight: bold;
    color: white;
  }
}
