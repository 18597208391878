.button-mint-container {
  position: fixed;
  z-index: 1;
  bottom: 60px;
  width: 100%;
  text-align: center;
}

.button-mint {
  padding: 8px 20px;
  border: unset;
  outline: unset;
  cursor: pointer;
  border-radius: 6px;
  font-size: 13px;
  text-align: center;
  transition: 0.5s;
  display: block;
  background: #81a2f1;
  color: white;
  margin: 0 auto;

  &:disabled {
    display: none;
  }

  &:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }
}


