.country-popup {
  width: 200px;
  height: auto;
  background: #202d3c;
  padding: 10px;
  border-radius: 8px;

  .title-cont {
    width: 100%;

    .title-img {
      width: 30px;
      height: 30px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px;
    }
    .title {
      font-size: 16px;
      font-weight: bold;
      color: white;
      width: 120px;
      display: inline-block;
      vertical-align: middle;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .country-infos {
    margin-top: 8px;

    .field-title {
      font-size: 12px;
      display: inline-block;
      vertical-align: middle;
    }
    .field-value {
      margin-left: 4px;
      font-weight: bold;
      font-size: 13px;
      width: 100px;
      display: inline-block;
      vertical-align: middle;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.show-my-lands {
  color: white;
  font-size: 12px;
  cursor: pointer;
  opacity: 0.7;
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1;

  &.active {
    opacity: 1;
  }
}
