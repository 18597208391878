.message {
  color: white;
  font-size: 13px;
  font-weight: bold;
  padding: 8px 20px;
  border-radius: 10px;
  width: 250px;
  position: fixed;
  top: -50px;
  left: calc(50% - 125px);
  transition: 0.33s linear all;
  z-index: 99;
  text-align: center;

  &.show {
    top: 50px;
    transition: 0.5s linear all;
  }

  &.warn {
    background: #ffcb4f;
  }
  &.error {
    background: #ff6258;
  }
  &.success {
    background: #42d75f;
  }
}
