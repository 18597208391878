.metamask-button {
  position: fixed;
  top: 20px;
  right: 20px;
  border-radius: 10px;
  background: #81a2f1;
  color: white;
  padding: 8px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  cursor: pointer;
  z-index: 99;

  &.connected {
    width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: white;
    overflow: hidden;
    display: block;
    opacity: 0.6;
  }

  &:hover {
    opacity: 0.6;
  }

  p {
    font-size: 12px;
    font-weight: bold;
    color: white;
    margin-right: 8px;
  }

  img {
    width: 32px;
    height: 32px;
  }

  @media screen and (max-width: 768px) {
    padding: 5px 8px;

    p {
      font-size: 12px;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }
}
