.minting-reward-container {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  .minting-reward-popup {
    width: 300px;
    height: auto;
    border-radius: 10px;
    text-align: center;
    background: #202d3c;
    text-align: center;
    padding: 20px 20px;

    .minting-reward-title {
      color: white;
      font-size: 20px;
      font-weight: bold;
    }

    .minting-reward-description {
      font-size: 12px;
      color: white;

      span {
        font-weight: bold;
      }
    }

    img {
      display: block;
      margin: 15px auto 15px;
      margin-top: 15px;
      width: 100%;
      height: 200px;
      border-radius: 5px;
    }

    button {
      outline: unset;
      cursor: pointer;
      border: unset;
      border-radius: 6px;
      padding: 8px 30px;
      color: white;
      background: #374a60;
      font-size: 12px;
      font-weight: bold;
      text-align: center;
      display: block;
      margin: 0 auto;
    }
  }

}
